<template>
    <manageLayout>
        <div class="p-inside-manage">
            <h1>Earnings</h1>
            <div class="wrap_manage_table under_construction">
                <span>In development</span>
            </div>
        </div>
    </manageLayout>
</template>

<script>
import manageLayout from "@/layouts/manageLayout";
export default {
    name: "earnings",
    components: {
        manageLayout
    },
}
</script>

<style scoped>

</style>